<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="float-right">

                    <button class="btn-danger btn btn-just-icon" @click="logout">
                        <span class="text-muted pr-2">{{user.name}}</span>
                        <i class="fas fa-power-off"></i>
                    </button>
                </div>
            </div>
            <div class="col-12  my-5">
                <img src="img/logo-color.png" class="img-fluid mx-auto d-block" style="max-width: 200px">
            </div>
            <div class="col-md-9">
                <h4 class="font-weight-bold text-center mb-3" style="opacity: .9">SELECCIONÁ LA APP A LA QUE QUERES INGRESAR.</h4>

                <div class="row justify-content-center">
                    <div class="col-12 col-md-4" v-for="app in user.apps" :key="app.name">
                        <div class="appItem shadow hover-brighten"
                             :style="{backgroundColor:app.hex}"
                             :class="[setDisabled(app)]"
                             @click="goTo(app.url,app.active)">
                            <b class="text-uppercase">{{app.name}}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        data() {
            return {
                apiUrl:process.env.VUE_APP_API_BASE_URL
            }
        },
        mounted() {},
        props: [],
        computed: {
            ...mapGetters({
                user:"getUser"
            }),
        },
        methods: {
            setDisabled(app) {
                return app.active ? null : 'disabled'
            },
            goTo(appUrl,enter) {
                if (!enter) return;

                this.$store.dispatch('getExternalToken')
                    .then(res=>{
                        let link = appUrl+
                            '?user='+this.user.email+
                            '&token='+res.data.data+
                            '&callback_url='+this.apiUrl+'/api/external_callback';

                        window.open(link);
                    })
                    .catch(err=>this.showError(err))
            },
            logout() {
                this.$store.dispatch('logout').then(()=>this.$router.push({name:'login'}));
            }
        },
    }
</script>

<style scoped lang="scss">
    .shadow {
        -webkit-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.4);
        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.4);

        &:not(.disabled):hover {
            -webkit-box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.8);
            -moz-box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.8);
            box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.8);
        }
    }

    .hover-brighten:not(.disabled):hover {
        filter: brightness(120%);
    }

    .appItem {
        text-align: center;
        border-radius: .5em;
        cursor: pointer;
        margin-bottom: 1em;
        height: 80px;
        line-height: 80px;
        color: white;

        &.disabled {
            cursor: unset;
            filter: brightness(50%);
        }

        @media (min-width: 768px) {
            height: 100px;
            line-height: 100px;
        }
        @media (min-width: 994px) {
            height: 150px;
            line-height: 150px;
        }
    }
</style>
